import React from "react";
import "./Header.scss";
import LoginComponent from "../account/LoginComponent";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import AnimatedIcon from "./AnimatedIcon";
import animationDataBlack from "./animations/data_inverted.json";
import animationData from "./animations/data.json";

const Header = ({ user, owner }) => {
  const history = useHistory();
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === "dark";
  const style = isDarkTheme ? { filter: "invert(1)" } : {};
  let name;
  if (user && owner && user.id === owner.id) {
    name = "My";
  } else if (owner) {
    name = owner.name + "'s";
  } else {
    name = "My";
  }

  const title = name + " lovely diary";

  function goHome() {
    if (user) {
      history.push(`/p/${user.id}`);
    }
  }

  return (
    <div className="Header">
      <div className="AppIcon" onClick={goHome}>
        <AnimatedIcon
          style={style}
          animationData={isDarkTheme ? animationDataBlack : animationData}
        />
      </div>
      <div className="HeaderTitle">{title}</div>
      <LoginComponent />
    </div>
  );
};

export default connect((state) => ({
  owner: state.page.owner,
  user: state.user.user,
}))(Header);
