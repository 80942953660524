export class PageAction {
  static get SetData() {
    return "PageAction_SetStories";
  }
  static get AddStory() {
    return "PageAction_AddStory";
  }
  static get StoryRemoved() {
    return "PageAction_StoryRemoved";
  }

  static get Reload() {
    return "PageAction_Reload";
  }
  static get UpdateStory() {
    return "PageAction_UpdateStory";
  }
  static get Switch() {
    return "PageAction_Switch";
  }
}

const initialState = {
  stories: null,
  owner: null,
  loadedForEditing: false
};

const setData = (state, action) => {
  return {
    ...state,
    stories: action.payload.stories,
    owner: action.payload.user,
    loadedForEditing: action.payload.loadedForEditing
  };
};

export const pageReducer = (state = initialState, action) => {
  if (action.type === PageAction.Switch) {
    return {
      stories: null,
      owner: null
    };
  }
  if (action.type === PageAction.Reload) {
    return {
      ...state,
      stories: null
    };
  }
  if (action.type === PageAction.AddStory) {
    return {
      ...state,
      stories: [action.payload.story, ...state.stories]
    };
  }
  if (action.type === PageAction.SetData) {
    return setData(state, action);
  }
  if (PageAction.StoryRemoved === action.type) {
    const storyId = action.payload.storyId;
    const filteredStoryList = state.stories.filter(item => {
      return item.id !== storyId;
    });
    return {
      ...state,
      stories: filteredStoryList
    };
  }
  if (PageAction.UpdateStory === action.type) {
    const storyId = action.payload.id;
    var indexOfItem;
    state.stories.forEach((element, index) => {
      if (element.id === storyId) {
        indexOfItem = index;
      }
    });
    const newList = state.stories;
    newList[indexOfItem] = action.payload;
    return { ...state, stories: newList };
  }
  return state;
};
