import React from "react";

function ImageSelector({ onImageSelected }) {
  const [isTooBig, setTooBig] = React.useState(false);
  function onChange(e) {
    const file = e.target.files[0];
    if (file.size > MAX_IMAGE_SIZE_BYTES) {
      setTooBig(true);
    } else {
      setTooBig(false);
      onImageSelected(file);
    }
  }
  return (
    <div className="ImageSelector">
      <input type="file" accept="image/*" onChange={e => onChange(e)} />

      {isTooBig ? (
        <div className="ImageSelectorError">Image is bigger then 1mb</div>
      ) : null}
    </div>
  );
}
export default ImageSelector;
const MAX_IMAGE_SIZE_BYTES = 1000000; //1mb
