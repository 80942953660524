//libs
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import StartPage from "./StartPage";
import GenericPageWithHeader from "./GenericPageWithHeader";

function IndexPage({ user }) {
  if (user.user) {
    return <Redirect to={`/p/${user.user.id}`} />;
  } else {
    return (
      <GenericPageWithHeader>
        <StartPage />
      </GenericPageWithHeader>
    );
  }
}

export default connect(state => ({
  user: state.user
}))(IndexPage);
