//libs
import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";
import _ from "lodash";

//comps
import { addContent as apiAddContent } from "../../api_actions/contentApiActions";
import { ErrorAction } from "../../reducers/errorReducer";
import { StoryAction } from "../../reducers/storyReducer";
import { isOwner } from "../../Utils";
import "./Content.scss";

function ContentControls({
  isOwner,
  contentItemAdded,
  contentItemAddError,
  page
}) {
  const [isLoading, setLoading] = React.useState(false);
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });
  const canEdit = isOwner && !selection.published;

  function addContent(contentType) {
    setLoading(true);
    apiAddContent(contentType, storyId)
      .then(result => {
        setLoading(false);
        contentItemAdded(result);
      })
      .catch(err => {
        setLoading(false);
        contentItemAddError(err);
      });
  }

  const controls = [
    { name: "Write", isShown: true, action: () => addContent("TextContent") },
    { name: "Say", isShown: true, action: () => addContent("AudioContent") },
    { name: "Show", isShown: true, action: () => addContent("ImageContent") },
  ].filter(item => {
    return item.isShown;
  });

  const buttons = controls.map(({ name, action }) => {
    return (
      <div key={name} className="ContentControl">
        <Button disabled={isLoading} onClick={action}>
          {name}
        </Button>
      </div>
    );
  });

  if (canEdit && !isLoading)
    return (
      <div className="ContentControls">
        <div className="ContentControlsDivider">
          <Divider />
        </div>
        <div className="ContentControlsContainer">{buttons}</div>
      </div>
    );
  else if (canEdit && isLoading)
    return (
      <div className="ContentControls">
        <div className="ContentControlsTopDivider">
          <Divider />
        </div>
        <LinearProgress />
        <div className="ContentControlsContainer">{buttons}</div>
      </div>
    );
  return null;
}

export default connect(
  state => ({
    page: state.page,
    story: state.story,
    token: state.token,
    isOwner: isOwner(state.user, state.page)
  }),
  dispatch => ({
    contentItemAdded: content => {
      dispatch({
        type: StoryAction.AddItem,
        payload: {
          content: content,
          storyId: content.storyId
        }
      });
    },
    contentItemAddError: err => {
      dispatch({
        type: ErrorAction.OnError,
        payload: {
          message: "Failed adding text content",
          err: err
        }
      });
    }
  })
)(ContentControls);
