//libs
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//comps
import GenericPageWithHeader from "./GenericPageWithHeader";
import LoginComponent from "../components/account/LoginComponent";
import "../components/page/Page.scss";

function NoStoryGuestPage({ user, owner }) {
  if (user) {
    return <HasLogin user={user} owner={owner} />;
  } else {
    return <NotLoggedIn owner={owner} />;
  }
}

function HasLogin({ user, owner }) {
  const name = user.name.split(" ")[0];
  const ownerName = owner.name.split(" ")[0];
  const { pageId } = useParams();

  return (
    <GenericPageWithHeader>
      <div className="StartPage">
        <div />
        <div className="NoPageContainer">
          <div className="NoPageContentItem">Hi, {name}.</div>
          <div className="NoPageContentItem">
            {ownerName} did not create any stories yet.
          </div>
        </div>
        <div />
      </div>
    </GenericPageWithHeader>
  );
}

function NotLoggedIn({ owner }) {
  const { pageId } = useParams();
  const ownerName = owner.name.split(" ")[0];

  return (
    <GenericPageWithHeader>
      <div className="StartPage">
        <div />
        <div className="NoPageContainer">
          <div className="NoPageContentItem">Hi, dear friend.</div>
          <div className="NoPageContentItem">
            {ownerName} did not create any stories yet.
          </div>
          <div className="NoPageContentItem">
            Now you can start your own diary:
          </div>
          <div className="NoPageContentItem">
            <LoginComponent />
          </div>
          
        </div>
        <div />
      </div>
    </GenericPageWithHeader>
  );
}

export default connect(state => ({
  user: state.user.user,
  owner: state.page.owner
}))(NoStoryGuestPage);
