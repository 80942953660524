import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useParams } from "react-router-dom";
import "./DateDetails.scss";

function DateDetails({ page }) {
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });
  const isPub = selection && selection.published;

  let view = <div />;
  if (isPub) {
    view = (
      <div className="DateDetailsContainer">
        
        <div className="DateDetailsRow">
          <div className="DateDetailName">Edited</div>
          <div className="DateDetailContent">
            {new Date(selection.updatedAt).toDateString()}
          </div>
        </div>
      </div>
    );
  }
  return view;
}

export default connect(state => ({
  page: state.page
}))(DateDetails);
