import React from "react";
import { MenuAction } from "../../reducers/menuReducer";
import { connect } from "react-redux";
import "./Header.scss";
import AnimatedIcon from "./AnimatedIcon";
import animationDataBlack from "./animations/data_inverted.json";
import animationData from "./animations/data.json";
import { useTheme } from "@material-ui/core/styles";


function DrawerButton({ menu, changeState }) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.type === "dark";
  const style = isDarkTheme ? { filter: "invert(1)" } : {};

  function handleClick() {
    changeState(!menu.isOpen);
  }
  
  return <div className="DrawerButton" onClick={handleClick}>
      <AnimatedIcon
          style={style}
          animationData={isDarkTheme ? animationDataBlack : animationData}
        />
  </div>;
}

export default connect(
  state => ({
    menu: state.menu
  }),
  dispatch => ({
    changeState: isOpen => {
      dispatch({ type: MenuAction.SetState, payload: { isOpen: isOpen } });
    }
  })
)(DrawerButton);
