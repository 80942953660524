export class MenuAction {
  static get SetState() {
    return "MenuAction_SetState";
  }
}

const initialState = { isOpen: false };

export const menuReducer = (state = initialState, action) => {
  if (MenuAction.SetState === action.type) {
    return {
      isOpen: action.payload.isOpen
    };
  }
  return state;
};
