//libs
import React from "react";
import { withUserAgent } from "react-useragent";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Storage from "@aws-amplify/storage";
import S3Audio from "./S3Audio";
import { nanoid } from 'nanoid'

//comps
import { isOwner } from "../../Utils";
import {
  apiRemoveContent,
  apiModifyContent
} from "../../api_actions/contentApiActions";
import ContentDispatcher from "./contentDispatcher";
import AudioSelector from "./AudioSelector";

function ContentAudio({
  content,
  isOwner,
  page,
  onRemoveContent,
  onRemoveContentError,
  onModifyContent,
  onModifyContentError,
  ua
}) {
  const noContent = !content.content || content.content.length === 0;
  const isMobile = ua.mobile;
  const [loading, setLoading] = React.useState(false);
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });
  const showControls = !loading && isOwner && !selection.published;
  const showFileSelector = noContent && isOwner;

  function onRemove() {
    setLoading(true);
    apiRemoveContent(content.id)
      .then(result => {
        onRemoveContent(result);
      })
      .catch(err => {
        setLoading(false);
        onRemoveContentError(err);
      });
  }

  const controls = (
    <div className="ContentItemControlsContainer">
      <Button onClick={onRemove} style={{ opacity: "0.5" }}>
        Remove
      </Button>
    </div>
  );

  const [progress, setProgress] = React.useState("");

  function onImageSelected(file) {
    setLoading(true);
    const fileName = file.name;
    const ext = fileName.split(".").slice(-1)[0];
    const imageName = nanoid() + "." + ext;
    Storage.put(imageName, file, {
      contentType: "audio/*",
      progressCallback(progress) {
        const p =
          100 -
          Math.floor(
            ((progress.total - progress.loaded) / progress.total) * 100
          );
        if (p === 100) {
          setProgress(null);
        } else {
          setProgress(`${p}`);
        }
      }
    })
      .then(result => {
        const contentData = {
          s3key: result.key,
          title: fileName
        };
        return apiModifyContent(content.id, JSON.stringify(contentData));
      })
      .then(res => {
        content.content = res.content;
        onModifyContent(content);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        onModifyContentError(err);
      });
  }

  const fileSelector = (
    <div className="ImageSelector">
      <div style={{ marginRight: "10px" }}>Audio Selector</div>
      <AudioSelector onAudioSelected={onImageSelected} />
    </div>
  );

  const progressDiv = (
    <div style={{ width: "20px", marginLeft: "5px" }}>{progress}</div>
  );
  if (loading) {
    return (
      <div className="ContentLoader">
        <div>Loading ... </div>
        {progressDiv}
      </div>
    );
  } else {
    let contentObj;
    let title;
    if (!noContent) {
      contentObj = JSON.parse(content.content);
      if (contentObj.title) {
        title = contentObj.title.split(".")[0];
      }
    }
    return (
      <div className="ContentImageContainer">
        {showFileSelector ? (
          fileSelector
        ) : (
          <S3Audio
            id={contentObj.s3key}
            alt={title}
            className={isMobile ? "MobileContentAudio" : "ContentAudio"}
          />
        )}
        {showControls ? controls : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    page: state.page,
    error: state.error,
    isOwner: isOwner(state.user, state.page)
  }),
  ContentDispatcher
)(withUserAgent(ContentAudio));
