//libs
import React, { Fragment } from "react";
import { connect } from "react-redux";

//comps
import { UserAction } from "../../reducers/userReducer";
import { PageAction } from "../../reducers/pageReducer";
import { SettingsAction } from "../../reducers/settingsReducer";
import { getMe, getSettingsApi } from "../../api_actions/userApiActions";
import LogInButton from "./LogInButton";
import AccountDetailsButton from "./AccountDetailsButton";
import "./LoginComponent.scss";

const LoginComponent = (props) => {
  let element;
  const userStore = props.user;

  const getMeFromApi = () => {
    getMe()
      .then((result) => {
        props.onGetMeSuccess(result);
        getSettingsFromApi(result.id);
      })
      .catch((err) => {
        props.onError(err);
      });
  };

  const getSettingsFromApi = (userId) => {
    getSettingsApi(userId)
      .then((result) => {
        props.onGetSettingsSuccess(result);
      })
      .catch((err) => {
        props.onError(err);
      });
  };

  if (!userStore.user) {
    getMeFromApi();
    element = <LogInButton />;
  } else {
    element = (
      <Fragment>
        <AccountDetailsButton user={userStore.user} />
      </Fragment>
    );
  }
  return <div className="LoginComponent">{element}</div>;
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    onGetMeSuccess: (result) => {
      dispatch({ type: UserAction.In, payload: { user: result } });
      dispatch({ type: PageAction.Reload });
    },
    onGetSettingsSuccess: (result) => {
      dispatch({ type: SettingsAction.SetAll, payload: { settings: result } });
    },
    onError: (err) => {
      console.warn("e", err);
    },
  })
)(LoginComponent);
