//libs
import React from "react";

//comps
import "../components/page/Page.scss";
import "./NoPage.scss";
import LoginComponent from "../components/account/LoginComponent";

function StartPage() {
  return (
    <div className="StartPage">
      <div />
      <div className="NoPageContainer">
        <div className="NoPageContentItem">Hi, dear friend.</div>
        <div className="NoPageContentItem">You are very welcome here.</div>
        <div className="NoPageContentItem">
          Now you can start your own diary:
        </div>
        <div className="NoPageContentItem">
          <LoginComponent />
        </div>
      </div>
      <div />
    </div>
  );
}

export default StartPage;
