import Storage from "@aws-amplify/storage";
import React from "react";
import { withUserAgent } from "react-useragent";

function S3Audio({ id, alt, style, className, ua }) {
  const [url, setUrl] = React.useState(null);
  const isMobile = ua.mobile;

  React.useEffect(() => {
    if (!url && id) {
      Storage.get(id).then(result => {
        setUrl(result);
      });
    }
  });

  return (
    <div>
      {url ? (
        <div>
          <audio
            className={className}
            style={style}
            controls
            src={url}
            preload={isMobile ? "metadata" : "auto"}
            loop={true}
            title={alt}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
          <div className="ContentSub">{alt}</div>
        </div>
      ) : (
        <div className={className} style={style}>
          {alt}
        </div>
      )}
    </div>
  );
}

export default withUserAgent(S3Audio);
