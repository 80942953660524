import React from "react";
import DrawerButton from "./DrawerButton";
import LoginComponent from "../account/LoginComponent";
import "./Header.scss";
import { connect } from "react-redux";

function MobileHeader({ user, owner }) {
  let name;
  if (user && owner && user.id === owner.id) {
    name = "My";
  } else if (owner) {
    name = owner.name.split(" ")[0] + "'s";
  } else {
    name = "My";
  }
  const title = name + " lovely diary";

  return (
    <div className="Header">
      <DrawerButton />
      <div className="MobileHeaderTitle">{title}</div>
      <LoginComponent />
    </div>
  );
}

export default connect(state => ({
  owner: state.page.owner,
  user: state.user.user
}))(MobileHeader);
