import Storage from "@aws-amplify/storage";
import React from "react";

function S3Image({ id, alt, style, className }) {
  const [url, setUrl] = React.useState(null);
  React.useEffect(() => {
    if (!url && id) {
      Storage.get(id).then(result => {
        setUrl(result);
      });
    }
  });

  return (
    <div>
      {url ? (
        <div>
          <img src={url} alt={alt} style={style} className={className} />
          <div className="ContentSub">{alt}</div>
        </div>
      ) : (
        <div className={className} style={style}>
          {alt}
        </div>
      )}
    </div>
  );
}

export default S3Image;
