export class StoryAction {
  static get SetStory() {
    return "StoryAction_SetStory";
  }
  static get UpdateItem() {
    return "StoryAction_UpdateItem";
  }
  static get AddItem() {
    return "StoryAction_AddItem";
  }
  static get RemoveItem() {
    return "StoryAction_RemoveItem";
  }
}

const initialState = {
  id: null,
  content: null
};

export function storyReducer(state = initialState, action) {
  if (StoryAction.SetStory === action.type) {
    return {
      content: action.payload.content,
      id: action.payload.id
    };
  }
  if (StoryAction.UpdateItem === action.type) {
    if (state.id !== action.payload.storyId) return state;
    const updatedContent = action.payload.content;
    var indexOfItem;
    state.content.forEach((element, index) => {
      if (element.id === updatedContent.id) {
        indexOfItem = index;
      }
    });
    const newContentList = state.content;
    newContentList[indexOfItem] = updatedContent;
    return { ...state, content: newContentList };
  }
  if (StoryAction.AddItem === action.type) {
    if (action.payload.storyId !== state.id) {
      return state;
    }
    const newContent = action.payload.content;
    const newContentList = state.content;
    newContentList.push(newContent);
    const newState = { ...state, content: newContentList };
    return newState;
  }
  if (StoryAction.RemoveItem === action.type) {
    if (state.id !== action.payload.storyId) return state;
    const removedContentId = action.payload.contentId;
    const newContentList = state.content.filter(item => {
      return item.id !== removedContentId;
    });
    const newState = { ...state, content: newContentList };
    return newState;
  }
  return state;
}
