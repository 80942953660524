export default class Content {
  static get TextContent() {
    return "TextContent";
  }
  static get ImageContent() {
    return "ImageContent";
  }
  static get AudioContent() {
    return "AudioContent";
  }
}
