//libs
import React, { Fragment } from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Button from "@material-ui/core/Button";

//comps
import {
  apiPublishStory,
  apiRemoveStory
} from "../../api_actions/contentApiActions";
import { ErrorAction } from "../../reducers/errorReducer";
import { PageAction } from "../../reducers/pageReducer";
import { isOwner } from "../../Utils";

function StoryControls({
  page,
  isOwner,
  story,
  onStoryPublishFailed,
  onStoryPublished,
  onStoryRemoved,
  onStoryRemoveFailed
}) {
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });

  const [loading, setLoading] = React.useState(false);
  const isPublishEnabled = story.content && story.content.length > 0;
  function remove() {
    setLoading(true);
    apiRemoveStory(selection.id)
      .then(result => {
        setLoading(false);
        onStoryRemoved(result);
      })
      .catch(err => {
        setLoading(false);
        onStoryRemoveFailed(err);
      });
  }
  function publish(shouldPublish) {
    setLoading(true);
    apiPublishStory(selection.id, shouldPublish)
      .then(result => {
        onStoryPublished();
      })
      .catch(err => {
        setLoading(false);
        onStoryPublishFailed(err);
      });
  }

  const publishTrue = () => {
    publish(true);
  };
  const publishFalse = () => {
    publish(false);
  };

  const isPub = selection && selection.published;

  const publishedControls = (
    <Fragment>
      <div className="EditingText">
        <i>Editing</i>
      </div>
      <Button onClick={remove}>Remove</Button>
      <Button
        disabled={loading === true || !isPublishEnabled}
        onClick={publishTrue}
      >
        Publish
      </Button>
    </Fragment>
  );

  const unPublishedControls = (
    <Fragment>
      <div className="EditingText">
        <i>Published</i>
      </div>
      <Button disabled={loading === true} onClick={publishFalse}>
        UnPublish
      </Button>
    </Fragment>
  );

  const buttons = !isPub ? publishedControls : unPublishedControls;

  if (!isOwner) {
    return null;
  } else if (!loading)
    return (
      <div className="StoryControls">
        <div className="StoryControlsContainer">{buttons}</div>
        <div className="StoryControlsDivider" style={{ height: "5px" }}>
          <Divider />
        </div>
      </div>
    );
  else
    return (
      <div className="StoryControls">
        <div className="StoryControlsContainer">{buttons}</div>
        <div className="StoryControlsDivider" style={{ height: "5px" }}>
          <LinearProgress />
        </div>
      </div>
    );
}

export default connect(
  state => ({
    story: state.story,
    page: state.page,
    isOwner: isOwner(state.user, state.page)
  }),
  dispatch => ({
    onStoryPublished: () => {
      dispatch({
        type: PageAction.Reload
      });
    },
    onStoryPublishFailed: err => {
      dispatch({
        type: ErrorAction.OnError,
        payload: { message: "Publishing story failed", err: err }
      });
    },
    onStoryRemoved: story => {
      dispatch({
        type: PageAction.StoryRemoved,
        payload: {
          storyId: story.id
        }
      });
    },
    onStoryRemoveFailed: err => {
      dispatch({
        type: ErrorAction.OnError,
        payload: { message: "Remove story failed", err: err }
      });
    }
  })
)(StoryControls);
