/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    picture
    name
    createdAt
    username
    email
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    picture
    name
    createdAt
    username
    email
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    picture
    name
    createdAt
    username
    email
  }
}
`;
export const createSettings = `mutation CreateSettings($input: CreateSettingsInput!) {
  createSettings(input: $input) {
    ownerId
    content
  }
}
`;
export const updateSettings = `mutation UpdateSettings($input: UpdateSettingsInput!) {
  updateSettings(input: $input) {
    ownerId
    content
  }
}
`;
export const deleteSettings = `mutation DeleteSettings($input: DeleteSettingsInput!) {
  deleteSettings(input: $input) {
    ownerId
    content
  }
}
`;
export const createContent = `mutation CreateContent($input: CreateContentInput!) {
  createContent(input: $input) {
    id
    contentType
    content
    createdAt
    updatedAt
    storyId
  }
}
`;
export const updateContent = `mutation UpdateContent($input: UpdateContentInput!) {
  updateContent(input: $input) {
    id
    contentType
    content
    createdAt
    updatedAt
    storyId
  }
}
`;
export const deleteContent = `mutation DeleteContent($input: DeleteContentInput!) {
  deleteContent(input: $input) {
    id
    contentType
    content
    createdAt
    updatedAt
    storyId
  }
}
`;
export const createStory = `mutation CreateStory($input: CreateStoryInput!) {
  createStory(input: $input) {
    id
    published
    createdAt
    updatedAt
    title
    ownerId
    bookId
  }
}
`;
export const updateStory = `mutation UpdateStory($input: UpdateStoryInput!) {
  updateStory(input: $input) {
    id
    published
    createdAt
    updatedAt
    title
    ownerId
    bookId
  }
}
`;
export const deleteStory = `mutation DeleteStory($input: DeleteStoryInput!) {
  deleteStory(input: $input) {
    id
    published
    createdAt
    updatedAt
    title
    ownerId
    bookId
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
    storyId
    updatedAt
    contentType
    content
    userId
    userName
  }
}
`;
export const updateComment = `mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    storyId
    updatedAt
    contentType
    content
    userId
    userName
  }
}
`;
export const deleteComment = `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    id
    storyId
    updatedAt
    contentType
    content
    userId
    userName
  }
}
`;
export const createEvent = `mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    id
    content
    type
    ownerId
    createdAt
    viewed
  }
}
`;
export const updateEvent = `mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    id
    content
    type
    ownerId
    createdAt
    viewed
  }
}
`;
export const deleteEvent = `mutation DeleteEvent($input: DeleteEventInput!) {
  deleteEvent(input: $input) {
    id
    content
    type
    ownerId
    createdAt
    viewed
  }
}
`;
