import { withUserAgent } from "react-useragent";
import React, { Fragment } from "react";
import Header from "../header/Header";
import MobileHeader from "../header/MobileHeader";


function HeaderWrapper({ ua }) {
  const isMobile = ua.mobile;
  return <Fragment>{isMobile ? <MobileHeader /> : <Header />}</Fragment>;
}

export default withUserAgent(HeaderWrapper);
