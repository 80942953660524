import  API, { graphqlOperation } from "@aws-amplify/api";
import { getUser as getUserApi, listStorys } from "../graphql/queries";
import { createStory as createStoryApi } from "../graphql/mutations";
import _ from "lodash";

export const getUser = async pageId => {
  return API.graphql(
    graphqlOperation(getUserApi, {
      id: pageId
    })
  ).then(result => {
    return result.data.getUser;
  });
};

export const getStories = async (pageId, userId) => {
  const owner = userId === pageId;
  return API.graphql(
    graphqlOperation(listStorys, {
      limit: 1000,
      filter: { ownerId: { eq: pageId } }
    })
  ).then(result => {
    const ordered = _.orderBy(
      result.data.listStorys.items,
      ["updatedAt"],
      ["desc"]
    );
    if (owner) return ordered;
    else
      return _.filter(ordered, item => {
        return item.published;
      });
  });
};

export const createStory = async userId => {
  return API.graphql(
    graphqlOperation(createStoryApi, {
      input: {
        title: `${new Date().toISOString()}`,
        published: false,
        ownerId: userId
      }
    })
  ).then(result => {
    return result.data.createStory;
  });
};
