import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { connect } from "react-redux";
import { MenuAction } from "../../reducers/menuReducer";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

import "./SideMenu.scss";
import SideMenu from "./SideMenu";

function MobileSideMenu({ menu, changeMenuState, isLoading, user, page }) {
  const path = useRouteMatch().path;
  const userListPath = "/mobileUsers";

  const history = useHistory();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    changeMenuState(open);
  };

  function showUserList() {
    history.push("/mobileUsers");
  }

  function navigateHome() {
    history.push(`/p/${user.id}`);
  }

  const shouldShowUsersOption = path !== userListPath;

  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const backgroundColor = theme.palette.background.default;

  const border = theme.overrides.border;
  const style = {
    color: textColor,
    borderBottom: border,
    background: backgroundColor,
  };

  const shouldShowHome = page.owner && user && user.id !== page.owner.id;
  const sideList = () => (
    <div
      className="Drawer"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {shouldShowHome ? (
        <div className="AddItem" style={style}>
          <div className="PageSideFragmentItemText" onClick={navigateHome}>
            Navigate home
          </div>
        </div>
      ) : null}

      {shouldShowUsersOption ? (
        <div className="AddItem" style={style}>
          <div className="PageSideFragmentItemText" onClick={showUserList}>
            Show user list
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <div className="LoadingItem">Loading ...</div>
      ) : (
        <SideMenu />
      )}
    </div>
  );

  return (
    <Drawer open={menu.isOpen} onClose={toggleDrawer(false)}>
      {sideList()}
    </Drawer>
  );
}

export default connect(
  (state) => ({
    menu: state.menu,
    user: state.user.user,
    page: state.page,
  }),
  (dispatch) => ({
    changeMenuState: (isOpen) => {
      dispatch({ type: MenuAction.SetState, payload: { isOpen: isOpen } });
    },
  })
)(MobileSideMenu);
