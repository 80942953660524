export class ErrorAction {
  static get OnError() {
    return "ErrorAction_onError";
  }
}

const initialState = {
  message: null,
  err: null
};

export const errorReducer = (state = initialState, action) => {
  if (action.type === ErrorAction.OnError) {
    return {
      message: action.payload.message,
      err: action.payload.err
    };
  }
  return state;
};
