//libs
import { withUserAgent } from "react-useragent";
import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Radium from 'radium'

//comps
import "../content/Content.scss";
import { isOwner } from "../../Utils";
import { ErrorAction } from "../../reducers/errorReducer";
import { PageAction } from "../../reducers/pageReducer";
import { apiModifyStoryTitle } from "../../api_actions/contentApiActions";
import { useTheme } from "@material-ui/core/styles";

const StoryTitle = ({
  page,
  ua,
  onUpdateStoryTitle,
  onUpdateStoryTitleError,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });

  React.useEffect(() => {
    return () => {
      if (currentInput !== selection.title && shouldSaveOnUnmount)
        onSaveStoryTitleAfterUnmount();
    };
  });

  const shouldShowEditButton =
    !editing && !loading && isOwner && !selection.published;

  const shouldShowEditMode =
    editing && !loading && isOwner && !selection.published;

  let currentInput = selection.title;
  let shouldSaveOnUnmount = true;

  function onChange(event) {
    currentInput = event.target.value;
  }

  function onEdit() {
    toggleEditMode();
  }

  function onCancel() {
    toggleEditMode();
    currentInput = selection.title;
  }
  function onSave() {
    if (currentInput === selection.title) {
      onCancel();
      return;
    }
    shouldSaveOnUnmount = false;
    setLoading(true);
    apiModifyStoryTitle(selection.id, currentInput)
      .then((result) => {
        setLoading(false);
        setEditing(false);
        onUpdateStoryTitle(result);
      })
      .catch((err) => {
        setLoading(false);
        onUpdateStoryTitleError(err);
      });
    currentInput = selection.title;
  }
  function onSaveStoryTitleAfterUnmount() {
    if (currentInput.length === 0 || currentInput === selection.title) {
      return;
    }
    apiModifyStoryTitle(selection.id, currentInput)
      .then((result) => {
        onUpdateStoryTitle(result);
      })
      .catch((err) => {
        onUpdateStoryTitleError(err);
      });
  }

  function toggleEditMode() {
    setEditing(!editing);
  }
  const isMobile = ua.mobile;
  const containerStyle = isMobile
    ? "MobileStoryTitleContainer"
    : "StoryTitleContainer";

  const previewModeControls = (
    <div className="ContentItemControlsContainer">
      <div />
      <Button onClick={onEdit}>Edit Title</Button>
    </div>
  );

  const editModeControls = (
    <div className="ContentItemControlsContainer">
      <div style={{ width: "70px" }} />
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onSave}>Save</Button>
    </div>
  );

  const display = <div className="StoryTitle">{selection.title}</div>;
  const theme = useTheme();
  const style = {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: theme.overrides.border,
    outlineColor: theme.overrides.divider,
  };

  const styleHover = {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    ":hover": { border: theme.overrides.border },
  };
  if (shouldShowEditMode) {
    return (
      <div className={containerStyle}>
        <input
          className="StoryTitleInput"
          maxLength="40"
          onChange={onChange}
          style={style}
          type="text"
          defaultValue={selection.title}
        />
        {editModeControls}
      </div>
    );
  } else if (shouldShowEditButton && !isMobile) {
    return (
      <div className={containerStyle}>
        <div
          className="EditOnHover"
          onClick={toggleEditMode}
          style={styleHover}
        >
          {display}
        </div>
      </div>
    );
  } else if (shouldShowEditButton) {
    return (
      <div className={containerStyle}>
        {display}
        {previewModeControls}
      </div>
    );
  } else if (loading) {
    return <div className="StoryLoader">Loading ...</div>;
  } else {
    return <div className={containerStyle}>{display}</div>;
  }
};

export default connect(
  (state) => ({
    page: state.page,
    isOwner: isOwner(state.user, state.page),
  }),
  (dispatch) => ({
    onUpdateStoryTitle: (story) => {
      dispatch({
        type: PageAction.UpdateStory,
        payload: story,
      });
    },
    onUpdateStoryTitleError: (err) => {
      dispatch({
        type: ErrorAction.OnError,
        payload: {
          err: err,
          message: "Change story title",
        },
      });
    },
  })
)(withUserAgent(Radium(StoryTitle)));
