//libs
import React from "react";
import { useParams, withRouter, Redirect } from "react-router-dom";

import { connect } from "react-redux";

//comps
import { getUser, getStories } from "../api_actions/storyApiActions";
import { PageAction } from "../reducers/pageReducer";
import NoStoryOwnerPage from "./NoStoryOwnerPage";
import NoStoryGuestPage from "./NoStoryGuestPage";
import GoodStoryPage from "./GoodStoryPage";
import GenericPageWithHeader from "./GenericPageWithHeader";

function PageLoader({ user, page, onFinishLoading }) {
  const { pageId, storyId } = useParams();
  const userId = user ? user.id : null;
  const [isFailed, setFailed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isThisUserOwner = userId === pageId;
  const shouldReload = isThisUserOwner && !page.loadedForEditing;
  const isLoaded =
    page.stories != null &&
    page.owner != null &&
    page.owner.id === pageId &&
    !shouldReload;

  React.useEffect(() => {
    if (!isLoaded && !isFailed && !loading) {
      load();
    }
  });

  let updateStoryId;
  if (!storyId && page.stories && page.stories.length > 0) {
    updateStoryId = page.stories[0].id;
  }

  function load() {
    setLoading(true);

    Promise.all([getStories(pageId, userId), getUser(pageId)])
      .then(result => {
        const storiesResult = result[0];
        const ownerResult = result[1];
        if (storiesResult == null || ownerResult == null) {
          setFailed(true);
        }
        setLoading(false);
        onFinishLoading(storiesResult, ownerResult, isThisUserOwner);
      })
      .catch(err => {
        console.warn(err);
        setFailed(true);
      });
  }

  let view;

  if (isLoaded && storyId) {
    view = view = <GoodStoryPage />;
  } else if (isLoaded && updateStoryId != null) {
    view = <Redirect to={`/p/${pageId}/${updateStoryId}`} />;
  } else if (isFailed) {
    view = <Redirect to={`/404`} />;
  } else if (isLoaded && !updateStoryId && isThisUserOwner) {
    view = <NoStoryOwnerPage />;
  } else if (isLoaded && !updateStoryId && !isThisUserOwner) {
    view = <NoStoryGuestPage />;
  } else {
    view = (
      <GenericPageWithHeader>
        <div>Loading ...</div>
      </GenericPageWithHeader>
    );
  }
  return view;
}

export default connect(
  state => ({
    user: state.user.user,
    page: state.page
  }),
  dispatch => ({
    onFinishLoading: (stories, owner, loadedForEditing) => {
      dispatch({
        type: PageAction.SetData,
        payload: {
          stories: stories,
          user: owner,
          loadedForEditing: loadedForEditing
        }
      });
    }
  })
)(withRouter(PageLoader));
