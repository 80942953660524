//libs
import React from "react";

//comps
import GenericPageWithHeader from "./GenericPageWithHeader";
import SideMenuWrapper from "../components/side_menu/SideMenuWrapper";
import { connect } from "react-redux";

function MobileUsersPage({}) {
  return (
    <GenericPageWithHeader>
      <SideMenuWrapper />
      <div className="NoPageContainer">
      </div>
    </GenericPageWithHeader>
  );
}

export default connect((state) => ({}))(MobileUsersPage);
