export class UserAction {
  static get In() {
    return "UserAction_In";
  }

  static get Out() {
    return "UserAction_Out";
  }
}

const initialState = {
  user: null
};

export const userReducer = (state = initialState, action) => {
  if (action.type === UserAction.In) {
    return {
      user: action.payload.user
    };
  }
  if (action.type === UserAction.Out) {
    return {
      user: null
    };
  }
  return state;
};
