import React from "react";
import HeaderWrapper from "../components/header/HeaderWrapper";
import "../components/page/Page.scss";
import { useTheme } from "@material-ui/core/styles";

function GenericPageWithHeader(props) {
  const theme = useTheme();
  return (
    <div
      className="Page"
      style={{
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <HeaderWrapper />
      <div className="PageContent">{props.children}</div>
    </div>
  );
}

export default GenericPageWithHeader;
