//libs
import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

//comps
import TextAreaWithChecker from "../../common_components/TextAreaWithChecker";
import { ErrorAction } from "../../../reducers/errorReducer";
import { createCommentApi } from "../../../api_actions/CommentsApiActions";
import { withUserAgent } from "react-useragent";
import { useTheme } from "@material-ui/core/styles";

function contentChecker(text) {
  if (!text || text.length === 0) {
    return {
      correct: false,
    };
  } else
    return {
      correct: true,
      value: text,
    };
}

function CommentCreate({ storyId, user, onCommentAdded, onError, ua }) {
  const [contentInput, setContent] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const isMobile = ua.mobile;

  function postComment(comment) {
    setLoading(true);

    createCommentApi(comment)
      .then((result) => {
        setLoading(false);
        onCommentAdded(result);
      })
      .catch((error) => {
        console.warn(error);
        const event = { message: "Create comment failed", err: error };
        onError(event);
        setLoading(false);
      });
  }

  function onSubmitUser() {
    const comment = {
      storyId: storyId,
      userId: user.id,
      contentType: "string",
      content: contentInput.value,
    };
    postComment(comment);
  }

  function onContentChange(text) {
    setContent(text);
  }

  const theme = useTheme();
  const style = {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: theme.overrides.border,
  };

  const className = isMobile ? "MobileCreateCommentItem" : "CreateCommentItem";
  let view;
  if (loading) {
    view = <div className={className}>Sending ...</div>;
  } else if (user) {
    const submitEnabled = contentInput && contentInput.correct;
    view = (
      <div className={className}>
        <TextAreaWithChecker
          style={style}
          checker={contentChecker}
          onInputChange={onContentChange}
          placeholder="Your comment"
          inputClassName="CommentInput"
        />
        <Button disabled={!submitEnabled} onClick={onSubmitUser}>
          Post
        </Button>
      </div>
    );
  } else {
    view = <div />;
  }
  return view;
}

export default connect(
  (state) => ({
    user: state.user.user,
  }),
  (dispatch) => ({
    onError: (err, message) => {
      dispatch({
        type: ErrorAction.OnError,
        payload: {
          message: message,
          err: err,
        },
      });
    },
  })
)(withUserAgent(CommentCreate));
