//libs
import { connect } from "react-redux";
import React from "react";
import { Redirect } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

//comps
import "./Profile.scss";
import GenericPageWithHeader from "./GenericPageWithHeader";
import EditRow from "../components/common_components/EditRow";
import { UserAction } from "../reducers/userReducer";
import { SettingsAction } from "../reducers/settingsReducer";
import LogoutButton from "../components/account/LogoutButton";
import MobileSideMenu from "../components/side_menu/MobileSideMenu";

import {
  updateUserApi,
} from "../api_actions/userApiActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withUserAgent } from "react-useragent";

function nameChecker(text) {
  if (!text || text.length < 2) {
    return {
      correct: false,
      error: "Name should be at lease 2 letters long",
    };
  } else
    return {
      correct: true,
      value: text,
    };
}

function MyProfile({ user, updateUser, ua }) {
  const isMobile = ua.mobile;

  const [isLoading, setLoading] = React.useState(false);
  if (!user) {
    return <Redirect to="/" />;
  }

  function saveUserName(value) {
    setLoading(true);
    updateUserApi({ ...user, name: value })
      .then((result) => {
        updateUser(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <GenericPageWithHeader>
      <div className="Profile">
        {isMobile ? <MobileSideMenu /> : null}
        <div />
        <div className="ProfileContent">
          <div className="ProfileTitle">Your profile</div>
          <EditRow
            isLoading={isLoading}
            defaultValue={user.name}
            placeholder="Name"
            checker={nameChecker}
            onSave={saveUserName}
          />
          <LogoutButton />
        </div>
        <div />
      </div>
    </GenericPageWithHeader>
  );
}

export default connect(
  (state) => ({ user: state.user.user, settings: state.settings }),
  (dispatch) => ({
    updateUser: (user) => {
      dispatch({ type: UserAction.In, payload: { user: user } });
    },
    updateSettings: (settings) => {
      dispatch({
        type: SettingsAction.SetAll,
        payload: { settings: settings },
      });
    },
  })
)(withUserAgent(MyProfile));
