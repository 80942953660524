import { StoryAction } from "../../reducers/storyReducer";
import { ErrorAction } from "../../reducers/errorReducer";

const ContentDispatcher = (dispatch) => ({
  onModifyContent: (data) => {
    dispatch({
      type: StoryAction.UpdateItem,
      payload: {
        storyId: data.storyId,
        content: data,
      },
    });
  },
  onModifyContentError: (err) => {
    dispatch({
      type: ErrorAction.OnError,
      payload: {
        err: err,
        message: "Modify content failed",
      },
    });
  },
  onRemoveContent: (content) => {
    dispatch({
      type: StoryAction.RemoveItem,
      payload: {
        contentId: content.id,
        storyId: content.storyId,
      },
    });
  },
  onRemoveContentError: (err) => {
    dispatch({
      type: ErrorAction.OnError,
      payload: {
        err: err,
        message: "Remove content failed",
      },
    });
  },
});

export default ContentDispatcher;
