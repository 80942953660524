//libs
import React from "react";
import { Hub } from "aws-amplify";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { UserAction } from "../reducers/userReducer";
import { SettingsAction } from "../reducers/settingsReducer";
import GenericPageWithHeader from "./GenericPageWithHeader";

//comps
import { getMe, getSettingsApi } from "../api_actions/userApiActions";

function LoginRedirect({ onError, onGetMeSuccess, onGetSettingsSuccess }) {
  const [isLoaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    Hub.listen("auth", data => {
      if (data.payload.event === "signIn") {
        getMe()
          .then(user => {
            onGetMeSuccess(user);
            getSettingsFromApi(user.id)
            setLoaded(true);
          })
          .catch(err => {
            onError(err);
          });
      }
    });
  });

    const getSettingsFromApi = (userId) => {
    getSettingsApi(userId)
      .then((result) => {
        onGetSettingsSuccess(result);
      })
      .catch((err) => {
        onError(err);
      });
  };

  let view = <GenericPageWithHeader>Loading ...</GenericPageWithHeader>;
  if (isLoaded) {
    view = <Redirect to={`/`} />;
  }
  return view;
}

export default withRouter(
  connect(
    state => ({}),
    dispatch => ({
      onGetMeSuccess: result => {
        dispatch({ type: UserAction.In, payload: { user: result } });
      },
      onGetSettingsSuccess: (result) => {
        dispatch({ type: SettingsAction.SetAll, payload: { settings: result } });
      },
      onError: err => {
        console.error(err);
      }
    })
  )(LoginRedirect)
);
