//libs
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Redirect, useParams } from "react-router-dom";

//comps
import { StoryAction } from "../reducers/storyReducer";
import GenericPageWithHeader from "./GenericPageWithHeader";
import { loadStoryContent } from "../api_actions/contentApiActions";
import Page from "../components/page/Page";

function GoodStoryPage({ page, story, onStoryDetailsLoaded }) {
  const { pageId, storyId } = useParams();

  const [isFailed, setIsFailed] = React.useState(false);
  const isLoaded =
    story.id === storyId && _.find(page.stories, { id: storyId });

  const hasStory =
    _.filter(page.stories, story => {
      return story.id === storyId;
    }).length > 0;

  function loadContent(storyId) {
    loadStoryContent(storyId)
      .then(response => {
        onStoryDetailsLoaded(storyId, response);
      })
      .catch(err => {
        console.error(err);
        setIsFailed(true);
      });
  }
  let view= (<div>wtf</div>);
  if (!hasStory) {
    view = <Redirect to={`/p/${pageId}`} />;
  } else if (isFailed) {
    view = <div>Failed ... </div>;
  } else {
    if (!isLoaded) loadContent(storyId);
    view = (
      <GenericPageWithHeader>
        <Page isLoaded={isLoaded} />
      </GenericPageWithHeader>
    );
  }
  return view;
}

export default connect(
  state => ({
    story: state.story,
    user: state.user,
    page: state.page
  }),
  dispatch => ({
    onStoryDetailsLoaded: (storyId, data) => {
      dispatch({
        type: StoryAction.SetStory,
        payload: {
          id: storyId,
          content: data
        }
      });
    }
  })
)(GoodStoryPage);
