export class CommentAction {
  static get SetComments() {
    return "CommentAction_SetComments";
  }
}

const initialState = {
  storyId: null,
  hasComments: null
};

export function commentReducer(state = initialState, action) {
  if (CommentAction.SetComments === action.type) {
    return {
      hasComments: action.payload.hasComments,
      storyId: action.payload.storyId
    };
  }

  return state;
}
