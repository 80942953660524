import React from "react";
import { withRouter } from "react-router-dom";
import { UserAction } from "../reducers/userReducer";
import { connect } from "react-redux";
import GenericPageWithHeader from "./GenericPageWithHeader";

function LogoutRedirect(props) {
  React.useEffect(() => {
    props.logout();
    props.history.replace("/");
  });
  return <GenericPageWithHeader>Loading ...</GenericPageWithHeader>;
}

export default connect(
  state => ({}),
  dispatch => ({
    logout: () => {
      dispatch({ type: UserAction.Out });
    }
  })
)(withRouter(LogoutRedirect));
