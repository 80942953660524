/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    picture
    name
    createdAt
    username
    email
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      picture
      name
      createdAt
      username
      email
    }
    nextToken
  }
}
`;
export const getSettings = `query GetSettings($ownerId: String!) {
  getSettings(ownerId: $ownerId) {
    ownerId
    content
  }
}
`;
export const listSettingss = `query ListSettingss(
  $ownerId: String
  $filter: ModelSettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSettingss(
    ownerId: $ownerId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      ownerId
      content
    }
    nextToken
  }
}
`;
export const getContent = `query GetContent($id: ID!) {
  getContent(id: $id) {
    id
    contentType
    content
    createdAt
    updatedAt
    storyId
  }
}
`;
export const listContents = `query ListContents(
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      contentType
      content
      createdAt
      updatedAt
      storyId
    }
    nextToken
  }
}
`;
export const getStory = `query GetStory($id: ID!) {
  getStory(id: $id) {
    id
    published
    createdAt
    updatedAt
    title
    ownerId
    bookId
  }
}
`;
export const listStorys = `query ListStorys(
  $filter: ModelStoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      published
      createdAt
      updatedAt
      title
      ownerId
      bookId
    }
    nextToken
  }
}
`;
export const getComment = `query GetComment($storyId: String!, $updatedAt: String!) {
  getComment(storyId: $storyId, updatedAt: $updatedAt) {
    id
    storyId
    updatedAt
    contentType
    content
    userId
    userName
  }
}
`;
export const listComments = `query ListComments(
  $storyId: String
  $updatedAt: ModelStringKeyConditionInput
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listComments(
    storyId: $storyId
    updatedAt: $updatedAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      storyId
      updatedAt
      contentType
      content
      userId
      userName
    }
    nextToken
  }
}
`;
export const getEvent = `query GetEvent($ownerId: String!, $createdAt: String!) {
  getEvent(ownerId: $ownerId, createdAt: $createdAt) {
    id
    content
    type
    ownerId
    createdAt
    viewed
  }
}
`;
export const listEvents = `query ListEvents(
  $ownerId: String
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEvents(
    ownerId: $ownerId
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      content
      type
      ownerId
      createdAt
      viewed
    }
    nextToken
  }
}
`;
