/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6fc1827e-3c32-472f-aaf0-b55da293fe8f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1v6nt3iul",
    "aws_user_pools_web_client_id": "3cqntahtv99i0sqr7sjqifivd4",
    "oauth": {
        "domain": "mylovelydiary808b8ab9-808b8ab9-proda.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.my-lovely-diary.com/login-redirect/,http://localhost:3000/login-redirect/",
        "redirectSignOut": "https://www.my-lovely-diary.com/logout-redirect/,http://localhost:3000/logout-redirect/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://7nsjektimfbs5m7a2wvruglp7q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-eapxmwkrvzaqpilr6ex4dcyubq",
    "aws_user_files_s3_bucket": "mylovelydiaryf042fcf25de140808982ca611c8ef1d7-proda",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
