//libs
import React from "react";
import { withUserAgent } from "react-useragent";
import { connect } from "react-redux";

//comps
import StoryComponent from "../story/StoryComponent";
import SideMenuWrapper from "../side_menu/SideMenuWrapper";

import "./Page.scss";
import "../side_menu/SideMenu.scss";

function Page({ isLoaded }) {
  return (
    <div className="Page">
      <SideMenuWrapper />
      <StoryComponent isLoaded={isLoaded} />
      <div/>
    </div>
  );
}

export default withUserAgent(connect((state) => ({ page: state.page }))(Page));
