//libs
import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Auth from "@aws-amplify/auth";

function LogInButton() {
  const [modal, setModal] = React.useState(false);

  function loginFacebook() {
    Auth.federatedSignIn({ provider: "Facebook" });
  }
  function loginGoogle() {
    Auth.federatedSignIn({ provider: "Google" });
  }
  function toggle() {
    setModal(!modal);
  }

  function logIn() {
    Auth.federatedSignIn();
  }

  return (
    <Fragment>
      <div className="LoginButton" onClick={logIn}>
        Log In
      </div>
      <Dialog open={modal} onClose={toggle}>
        <DialogTitle id="alert-dialog-title">{"Login"}</DialogTitle>

        <DialogContent>
          <Fragment>
            <div
              className="LoginButtonFB"
              onClick={() => {
                loginFacebook();
                toggle();
              }}
            >
              Login Facebook
            </div>
            <div
              className="LoginButtonGoogle"
              onClick={() => {
                loginGoogle();
                toggle();
              }}
            >
              Login Google
            </div>
            <br />
            <br />
            <br />
          </Fragment>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default LogInButton;
