import API, { graphqlOperation } from "@aws-amplify/api";
import { listComments } from "../../src/graphql/queries";
import { nanoid } from "nanoid";
import {
  createComment,
  updateComment,
  deleteComment,
} from "../../src/graphql/mutations";

export const getCommentsApi = (storyId, nextToken) => {
  return API.graphql(
    graphqlOperation(listComments, {
      storyId: storyId,
      nextToken: nextToken,
      sortDirection: "DESC",
      limit: 3,
    })
  ).then((result) => {
    return result.data.listComments;
  });
};

export const createCommentApi = async (comment) => {
  return API.graphql(
    graphqlOperation(createComment, {
      input: { ...comment, id: nanoid(), updatedAt: new Date().toISOString() },
    })
  ).then((result) => {
    return result.data.createComment;
  });
};

export const deleteCommentApi = async (commentId) => {
  return API.graphql(
    graphqlOperation(deleteComment, {
      input: { id: commentId },
    })
  ).then((result) => {
    return result.data.deleteComment;
  });
};

export const updateCommentApi = async (comment) => {
  return API.graphql(
    graphqlOperation(updateComment, {
      input: { comment },
    })
  ).then((result) => {
    return result.data.updateComment;
  });
};
