import Auth from "@aws-amplify/auth";
import Api, { graphqlOperation } from "@aws-amplify/api";
import { getUser, listUsers, getSettings } from "../graphql/queries";
import { updateUser, createSettings } from "../graphql/mutations";

export const getMe = () => {
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return mapCogintoUser(user);
    })
    .then((user) => {
      return getUserApi(user.id);
    });
};

export const getUserApi = (userId) => {
  return Api.graphql(graphqlOperation(getUser, { id: userId })).then(
    (result) => {
      return result.data.getUser;
    }
  );
};

export const updateUserApi = (user) => {
  return Api.graphql(graphqlOperation(updateUser, { input: { ...user } })).then(
    (result) => {
      return result.data.updateUser;
    }
  );
};

export const getSettingsApi = (userId) => {
  return Api.graphql(graphqlOperation(getSettings, { ownerId: userId })).then(
    (result) => {
      const settings = result.data.getSettings;
      if (settings === null) return;
      const content = result.data.getSettings.content;
      return JSON.parse(content);
    }
  );
};

export const updateSettingsApi = (userId, settings) => {
  return Api.graphql(
    graphqlOperation(createSettings, {
      input: { ownerId: userId, content: JSON.stringify(settings) },
    })
  ).then((result) => {
    return JSON.parse(result.data.createSettings.content);
  });
};

function mapCogintoUser(cognito) {
  const name = cognito.username.split("_");
  if (name[0] === "Facebook") {
    const picture = `https://graph.facebook.com/${name[1]}/picture?type=square`;
    cognito.attributes["picture"] = picture;
    cognito.attributes["map_id"] = name[1];
  } else if (name[0] === "Google") {
    cognito.attributes["map_id"] = name[1];
  } else {
    cognito.attributes["map_id"] = encode(cognito.attributes["email"]);
  }

  return {
    id: cognito.attributes["map_id"],
    picture: cognito.attributes["picture"],
    name: cognito.attributes["name"],
    username: cognito.username,
  };
}

export async function getUsersListApi() {
  return Api.graphql(graphqlOperation(listUsers)).then((result) => {
    return result.data.listUsers.items;
  });
}

function encode(input) {
  var out = "";
  for (var i = 0; i < input.length; i++) {
    out += Math.abs(input.charCodeAt(i)).toString();
  }
  return out;
}
