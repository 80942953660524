import API, { graphqlOperation } from "@aws-amplify/api";
import { listContents } from "../../src/graphql/queries";
import {
  createContent,
  updateStory,
  deleteContent,
  deleteStory,
  updateContent
} from "../../src/graphql/mutations";
import _ from "lodash";

export const loadStoryContent = async storyId => {
  return API.graphql(
    graphqlOperation(listContents, {
      limit: 1000,
      filter: { storyId: { eq: storyId } }
    })
  ).then(result => {
    return _.sortBy(result.data.listContents.items, ["createdAt"], ["desc"]);
  });
};

export const addContent = async (contentType, storyId) => {
  return API.graphql(
    graphqlOperation(createContent, {
      input: { storyId: storyId, contentType: contentType }
    })
  ).then(result => {
    return result.data.createContent;
  });
};

export const apiPublishStory = async (storyId, shouldPublish) => {
  return API.graphql(
    graphqlOperation(updateStory, {
      input: { id: storyId, published: shouldPublish }
    })
  ).then(result => {
    return result.data.updateStory;
  });
};

export const apiRemoveContent = async contentId => {
  return API.graphql(
    graphqlOperation(deleteContent, { input: { id: contentId } })
  ).then(result => {
    return result.data.deleteContent;
  });
};

export const apiModifyContent = async (contentId, content) => {
  return API.graphql(
    graphqlOperation(updateContent, {
      input: {
        id: contentId,
        content: content
      }
    })
  ).then(result => {
    return result.data.updateContent;
  });
};

export const apiModifyStoryTitle = async (storyId, title) => {
  return API.graphql(
    graphqlOperation(updateStory, {
      input: { id: storyId, title: title }
    })
  ).then(result => {
    return result.data.updateStory;
  });
};

export const apiRemoveStory = async storyId => {
  return API.graphql(
    graphqlOperation(deleteStory, { input: { id: storyId } })
  ).then(result => {
    return result.data.deleteStory;
  });
};
