//libs
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withUserAgent } from "react-useragent";

//comps
import "./SideMenu.scss";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import DisplayItem from "./DisplayItem";
import EmptyItem from "./EmptyItem";
import { isOwner } from "../../Utils";
import { useTheme } from "@material-ui/core/styles";

const SideMenu = (props) => {
  const user = props.user;
  const page = props.page;
  const isMobile = props.ua.mobile;
  let content;

  const addItem = isOwner(user, page) ? <AddItem /> : null;
  if (!page.stories || page.stories.length === 0) {
    const emptyItem = isMobile ? <EmptyItem /> : null;
    content = (
      <Fragment>
        {addItem}
        {emptyItem}
      </Fragment>
    );
  } else {
    const map = page.stories.map((item) => {
      if (item.published) {
        return <DisplayItem story={item} key={item.id} />;
      } else {
        return <EditItem story={item} key={item.id} />;
      }
    });
    content = (
      <Fragment>
        {addItem}
        {map}
      </Fragment>
    );
  }
  const theme = useTheme();
  const color = theme.overrides.divider
  const border = theme.overrides.border
  const style = {
    background: color,
    borderTop: border,
    borderLeft: border,
    borderRight: border,
  };

  return (
    <div className="SideMenu">
      <div className="PageSideFragmentHeader" style={style}>
        Stories
      </div>
      <div className="PageSideFragmentContentList" style={style}>{content}</div>
    </div>
  )

};

export default connect((state) => ({
  page: state.page,
  user: state.user,
}))(withUserAgent(SideMenu));
