//libs
import React, { Fragment } from "react";
import { connect } from "react-redux";
import TextareaAutosize from "react-autosize-textarea";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { withUserAgent } from "react-useragent";
import { useTheme } from "@material-ui/core/styles";
import Radium from 'radium'

//comps
import { isOwner } from "../../Utils";
import {
  apiRemoveContent,
  apiModifyContent,
} from "../../api_actions/contentApiActions";
import ContentDispatcher from "./contentDispatcher";
import "./Content.scss";

function ContentText({
  content,
  isOwner,
  page,
  onRemoveContent,
  onRemoveContentError,
  onModifyContent,
  onModifyContentError,
  ua,
}) {
  const isMobile = ua.mobile;
  const noContent = !content.content || content.content.length === 0;
  const editByDefault = noContent && isOwner;
  const [editing, setEditing] = React.useState(editByDefault);
  const [loading, setLoading] = React.useState(false);
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });

  const shouldShowEditMode =
    editing && !loading && isOwner && !selection.published;
  const shouldShowEditButton =
    !editing && !loading && isOwner && !selection.published;

  let currentInput = content.content;
  let shouldSaveOnUnmount = true;
  React.useEffect(() => {
    return () => {
      if (currentInput !== content.content && shouldSaveOnUnmount)
        onSaveAfterUnmount();
    };
  });

  function onSaveAfterUnmount() {
    if (currentInput === content.content) {
      return;
    }
    apiModifyContent(content.id, currentInput)
      .then((result) => {
        onModifyContent(result);
      })
      .catch((err) => {
        onModifyContentError(err);
      });
  }

  function onChange(event) {
    currentInput = event.target.value;
  }
  function toggleEditMode() {
    setEditing(!editing);
  }
  function onSave() {
    setEditing(false);
    if (currentInput === content.content) {
      return;
    }
    shouldSaveOnUnmount = false;

    onModifyContent({ ...content, content: currentInput });

    apiModifyContent(content.id, currentInput)
      .then((result) => {
        //noop
      })
      .catch((err) => {
        shouldSaveOnUnmount = true;
        onModifyContentError(err);
      });
  }
  function onCancel() {
    currentInput = content.content;
    toggleEditMode();
  }

  function onRemove() {
    currentInput = content.content;
    setLoading(true);
    apiRemoveContent(content.id)
      .then((result) => {
        onRemoveContent(result);
      })
      .catch((err) => {
        setLoading(false);
        onRemoveContentError(err);
      });
  }

  const previewModeControls = (
    <Fragment>
      <div className="ContentItemControlsContainer">
        <div />
        <Button onClick={toggleEditMode}>Edit</Button>
      </div>
    </Fragment>
  );

  const editModeControls = (
    <div className="ContentItemControlsContainer">
      <Button onClick={onRemove} style={{ opacity: "0.5" }}>
        Remove
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onSave}>Save</Button>
    </div>
  );
  const theme = useTheme();
  const style = {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: theme.overrides.border,
  };

  const styleHover = {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    ':hover': { border: theme.overrides.border },
  };

  const display = <p className="ContentTextStyle">{content.content}</p>;
  let contentView;
  if (shouldShowEditMode) {
    contentView = (
      <div>
        <TextareaAutosize
          type="text"
          name="content"
          className="TextInput"
          style={style}
          onChange={onChange}
          defaultValue={currentInput}
        />
        {editModeControls}
      </div>
    );
  } else if (loading) {
    contentView = <div className="ContentLoader">Loading ...</div>;
  } else if (shouldShowEditButton && !isMobile) {
    contentView = (
      <div className="EditOnHover" onClick={toggleEditMode} style={styleHover}>
        {display}
      </div>
    );
  } else if (shouldShowEditButton && isMobile) {
    contentView = (
      <Fragment>
        {display}
        {previewModeControls}
      </Fragment>
    );
  } else {
    contentView = display;
  }
  return (
    <div className={isMobile ? "MobileContentText" : "ContentText"}>
      {contentView}
    </div>
  );
}

export default connect(
  (state) => ({
    page: state.page,
    error: state.error,
    isOwner: isOwner(state.user, state.page),
  }),
  ContentDispatcher
)(withUserAgent(Radium(ContentText)));
