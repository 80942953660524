//libs
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//comps
import GenericPageWithHeader from "./GenericPageWithHeader";
import "./NoPage.scss";
import "../components/page/Page.scss";
import AddItem from "../components/side_menu/AddItem";

function NoStoryOwnerPage({ user }) {
  const { pageId } = useParams();
  const name = user.name.split(" ")[0];
  return (
    <GenericPageWithHeader>
      <div className="StartPage">
        <div />
        <div className="NoPageContainer">
          <div className="NoPageContentItem">Hi, {name}.</div>
          <div className="NoPageContentItem">
            Thank you for creating an account.
          </div>
          <div className="NoPageContentItem">You are very welcome here.</div>
          <div className="NoPageContentItem">
            Now you can create your first story:
          </div>
          <div className="NoPageContentItem">
            <AddItem
              className="NoPageAddItem"
              loadingClassName="NoPageLoadingItem"
            />
          </div>
        </div>
        <div />
      </div>
    </GenericPageWithHeader>
  );
}

export default connect(state => ({
  user: state.user.user
}))(NoStoryOwnerPage);
