//libs
import Avatar from "@material-ui/core/Avatar";
import React from "react";
import { useHistory } from "react-router-dom";

//comps
import "./LoginComponent.scss";
import "./LogoutButton";

export default function AccountDetailsButton({ user }) {
  const history = useHistory();
  
  function openMyProfile() {
    history.push("/myprofile");
  }

  return (
    <div className="UserAndAvatar">
      <Avatar className="Avatar" src={user.picture} onClick={openMyProfile} />
    </div>
  );
}
