//libs
import { useParams } from "react-router-dom";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withUserAgent } from "react-useragent";
import _ from "lodash";

//comps
import ContentEmpty from "../content/ContentEmpty";
import ContentText from "../content/ContentText";
import ContentAudio from "../content/ContentAudio";
import ContentImage from "../content/ContentImage";
import StoryTitle from "./StoryTitle";
import "./StoryComponent.scss";
import ContentControls from "../content/ContentControls";
import StoryControls from "./StoryControls";
import Content from "../content/Content";
import CommentComponent from "./comments/CommentComponent";
import DateDetails from "./DateDetails";

function StoryComponent({ ua, story, isLoaded, page }) {
  const isMobile = ua.mobile;
  const { storyId } = useParams();

  let view;
  if (!isLoaded) {
    //noop
  } else if (!storyId) {
    view = <ContentEmpty className="ContentEmpty" />;
  } else {
    const storyDetails = _.find(page.stories, { id: storyId });
    const content = story.content.map(item => {
      if (item.contentType === Content.TextContent) {
        return <ContentText content={item} key={item.id} />;
      } else if (item.contentType === Content.ImageContent) {
        return <ContentImage content={item} key={item.id} />;
      } else if (item.contentType === Content.AudioContent) {
        return <ContentAudio content={item} key={item.id} />;
      } else throw new Error(`Unknown content type ${item.contentType}`);
    });
    view = (
      <Fragment>
        <StoryControls />
        <StoryTitle />
        {content}
        <DateDetails />
        <ContentControls />
        <CommentComponent story={storyDetails} />
      </Fragment>
    );
  }
  return (
    <div className={isMobile ? "MobileStoryComponent" : "StoryComponent"}>
      {view}
      <div className="Footer" />
    </div>
  );
}

export default connect(state => ({
  story: state.story,
  user: state.user,
  page: state.page
}))(withUserAgent(StoryComponent));
