import React from "react";
import InputWithChecker from "../components/common_components/InputWithChecker";

function inputChecker(text) {
  if (!text || text.length < 2) {
    return {
      correct: false,
      error: "text is shorted then 2 chars"
    };
  } else
    return {
      correct: true,
      input: text
    };
}

function onInputChange(inputResult) {
  console.log(inputResult);
}

export default function DevPage(props) {
  return (
    <div>
      <InputWithChecker
        inputType="text"
        defaultValue="hello"
        placeholder="hello"
        checker={inputChecker}
        onInputChange={onInputChange}
      />
    </div>
  );
}
