import React from "react";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

function DisplayItem(props) {
  const { storyId } = useParams();

  const isSelected = storyId === props.story.id;
  const handleClick = () => {
    if (!isSelected) {
      props.history.push(`/p/${props.story.ownerId}/${props.story.id}`);
    }
  };

  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const selectedTextColor = theme.overrides.selectedTextColor;
  const selectedBackgroundColor = theme.overrides.selectedBackgroundColor;
  const backgroundColor = theme.palette.background.default;

  const border = theme.overrides.border;
  const regularStyle = {
    color: textColor,
    borderBottom: border,
    background: backgroundColor,
  };

  const selectedStyle = {
    color: selectedTextColor,
    background: selectedBackgroundColor,
    borderBottom: border,
  };

  return (
    <div
      onClick={handleClick}
      className={isSelected ? "DisplayItemSelected" : "DisplayItem"}
      style={isSelected ? selectedStyle : regularStyle}
    >
      <div className="PageSideFragmentItemText">{props.story.title} </div>
    </div>
  );
}

export default withRouter(DisplayItem);
