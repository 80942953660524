export class UserCacheAction {
  static get AddItems() {
    return "UserCacheAction_AddItems";
  }
}
const initialState = {};
export const userCacheReducer = (state = initialState, action) => {
  if (action.type === UserCacheAction.AddItems) {
    const newState = {
      ...state
    };
    action.items.forEach(item => {
      newState[item.id] = item;
    });
    return newState;
  }
  return state;
};
