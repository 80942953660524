//libs
import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";

//comps
import "./EditRow.scss";
import InputWithChecker from "./InputWithChecker";

function EditRow({ defaultValue, placeholder, checker, onSave, isLoading }) {
  const [isEdit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [canSave, setCanSave] = React.useState(false);

  function onInputChange(change) {
    setCanSave(change.correct && change.value !== defaultValue);
    setValue(change.value);
  }

  function renderEdit() {
    return (
      <Fragment>
        <Fragment>{getFieldTitle(placeholder)}</Fragment>
        <InputWithChecker
          defaultValue={defaultValue}
          onInputChange={onInputChange}
          className="EditRowContent"
          checker={checker}
        />
        {getButtons(isEdit)}
      </Fragment>
    );
  }
  function renderPreview() {
    return (
      <Fragment>
        <Fragment>{getFieldTitle(placeholder)}</Fragment>
        {isLoading ? (
          <div className="EditRowFieldTitle">Loading ... </div>
        ) : (
          <Fragment>
            <div className="EditRowContent">{defaultValue}</div>
            <Fragment>{getButtons(isEdit)}</Fragment>
          </Fragment>
        )}
      </Fragment>
    );
  }

  function getFieldTitle(placeholder) {
    return <div className="EditRowFieldTitle">{placeholder}:</div>;
  }

  function getButtons(isEdit) {
    return (
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        {isEdit && canSave ? (
          <Button
            onClick={() => {
              setEdit(false);
              onSave(value);
            }}
          >
            Save
          </Button>
        ) : null}
        <Button
          onClick={() => {
            setEdit(!isEdit);
          }}
        >
          {isEdit ? "Cancel" : "Edit"}
        </Button>
      </div>
    );
  }

  return (
    <div className="EditRow">{isEdit ? renderEdit() : renderPreview()}</div>
  );
}

export default EditRow;
