import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { pageReducer } from "./pageReducer";
import { storyReducer } from "./storyReducer";
import { errorReducer } from "./errorReducer";
import { menuReducer } from "./menuReducer";
import { commentReducer } from "./commentReducer";
import { userCacheReducer } from "./usersCacheReducer";
import { settingsReducer } from "./settingsReducer";

const CombinedReducer = combineReducers({
  user: userReducer,
  page: pageReducer,
  story: storyReducer,
  error: errorReducer,
  menu: menuReducer,
  comments: commentReducer,
  userCache: userCacheReducer,
  settings: settingsReducer,
});

export default CombinedReducer;
