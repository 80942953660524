import { withUserAgent } from "react-useragent";
import React from "react";
import SideMenu from "./SideMenu";
import MobileSideMenu from "./MobileSideMenu";

function SideMenuWrapper({ ua }) {
  const isMobile = ua.mobile;
  return isMobile ? <MobileSideMenu /> : <SideMenu />;
}

export default withUserAgent(SideMenuWrapper);
