//libs
import React from "react";
import { withUserAgent } from "react-useragent";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Storage from "@aws-amplify/storage";
import { nanoid } from "nanoid";
import S3Image from "./S3Image";

//comps
import { isOwner } from "../../Utils";
import {
  apiRemoveContent,
  apiModifyContent,
} from "../../api_actions/contentApiActions";
import ContentDispatcher from "./contentDispatcher";
import ImageSelector from "./ImageSelector";

function ContentImage({
  content,
  isOwner,
  page,
  onRemoveContent,
  onRemoveContentError,
  onModifyContent,
  onModifyContentError,
  ua,
}) {
  const noContent = !content.content || content.content.length === 0;
  const isMobile = ua.mobile;
  const [loading, setLoading] = React.useState(false);
  const { storyId } = useParams();
  const selection = _.find(page.stories, { id: storyId });
  const showControls = !loading && isOwner && !selection.published;
  const showImageSelector = noContent && isOwner;

  function onRemove() {
    setLoading(true);
    apiRemoveContent(content.id)
      .then((result) => {
        onRemoveContent(result);
      })
      .catch((err) => {
        setLoading(false);
        onRemoveContentError(err);
      });
  }

  const controls = (
    <div className="ContentItemControlsContainer">
      <Button onClick={onRemove} style={{ opacity: "0.5" }}>
        Remove
      </Button>
    </div>
  );

  function onImageSelected(file) {
    setLoading(true);
    const fileName = file.name;
    const ext = fileName.split(".").slice(-1)[0];
    const imageName = nanoid() + "." + ext;
    Storage.put(imageName, file, {
      contentType: "image/*",
    })
      .then((result) => {
        const contentData = {
          s3key: result.key,
          title: fileName,
        };
        return apiModifyContent(content.id, JSON.stringify(contentData));
      })
      .then((res) => {
        content.content = res.content;
        onModifyContent(content);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        onModifyContentError(err);
      });
  }

  const imageSelector = (
    <div className="ImageSelectorContainer">
      <div style={{ marginRight: "10px" }}>Image Selector</div>
      <ImageSelector onImageSelected={onImageSelected} />
    </div>
  );

  if (loading) {
    return <div className="ContentLoader">Loading ...</div>;
  } else {
    return (
      <div className="ContentImageContainer">
        {showImageSelector ? (
          imageSelector
        ) : (
          <S3Image
            id={JSON.parse(content.content).s3key}
            className={isMobile ? "MobileContentImage" : "ContentImage"}
          />
        )}
        {showControls ? controls : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    page: state.page,
    error: state.error,
    isOwner: isOwner(state.user, state.page),
  }),
  ContentDispatcher
)(withUserAgent(ContentImage));
