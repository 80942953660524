import React from "react";
import "./Content.scss"

export default function ContentEmpty(props) {
  const propsHasClass = props.className;
  return (
    <div className={propsHasClass ? props.className : "ContentEmpty"}>
      Nothing here yet
    </div>
  );
}

