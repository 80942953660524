//libs
import React from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

//comps
import { ErrorAction } from "../../reducers/errorReducer";

const snackbarStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

function ErrorSnackbar({ error, hideError }) {
  const classes = snackbarStyles();

  function isOpen() {
    return error.message != null;
  }

  if (isOpen()) {
    console.warn(error);
  }
  function handleClose(event, reason) {
    hideError();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      onClose={handleClose}
      open={isOpen()}
      autoHideDuration={4000}
    >
      <SnackbarContent className={classes.error} message={error.message} />
    </Snackbar>
  );
}

export default connect(
  state => ({ error: state.error }),
  dispatch => ({
    hideError: () => {
      dispatch({
        type: ErrorAction.OnError,
        payload: {
          err: null,
          message: null
        }
      });
    }
  })
)(ErrorSnackbar);
