export class SettingsAction {
  static get SetDarkMode() {
    return "SettingsAction_ SetDarkMode";
  }

  static get SetAll() {
    return "SettingsAction_ SetAll";
  }
}

export const initialState = {
  isDarkTheme: false,
};

function restoreSettings() {
  const fromSettings = localStorage.getItem("MLDsettings");
  if (fromSettings == null) return null;
  return JSON.parse(fromSettings);
}

function saveSettings(settings) {
  localStorage.setItem("MLDsettings", JSON.stringify(settings));
}

const fromSettings = restoreSettings();

export function settingsReducer(
  state = fromSettings != null ? fromSettings : initialState,
  action
) {
  if (SettingsAction.SetDarkMode === action.type) {
    const newState = { ...state, isDarkTheme: action.payload.isDarkTheme };
    saveSettings(newState);
    return newState;
  }
  if (SettingsAction.SetAll === action.type) {
    if (action.payload.settings === null) {
      console.log("settings null");
      return initialState;
    }
    saveSettings(action.payload.settings);
    return action.payload.settings;
  }

  return state;
}
