import React from "react";
import { connect } from "react-redux";
import { UserCacheAction } from "../../../reducers/usersCacheReducer";
import { getUserApi } from "../../../api_actions/userApiActions";
import Avatar from "@material-ui/core/Avatar";
import { withUserAgent } from "react-useragent";
import { useTheme } from "@material-ui/core/styles";

function CommentItemView({ comment, cacheUser, userCache, ua }) {
  const [owner, setOwner] = React.useState(userCache[comment.userId]);
  const [loading, setLoading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const isMobile = ua.mobile;
  const theme = useTheme();

  React.useEffect(() => {
    if (comment.userId && !owner && !loading && !failed) {
      fetchOwner(comment.userId);
    }
  });

  const className = isMobile ? "MobileCommentItem" : "CommentItem";
  const textStyle = { color: theme.palette.text.primary };

  let view = <div>{comment.content}</div>;
  if (failed) {
  } else if (comment.userName) {
    view = (
      <div className={className}>
        <div className="CommentUserDetails">
          <Avatar />
          <div className="ContactName">{comment.userName}</div>
        </div>
        <pre style={textStyle} className="CommentContent">
          {comment.content}
        </pre>
      </div>
    );
  } else if (loading || !owner) {
    view = <div>Loading ... </div>;
  } else {
    view = (
      <div className={className}>
        <div className="CommentUserDetails">
          <Avatar src={owner.picture} />
          <div className="ContactName">{owner.name}</div>
        </div>
        <pre style={textStyle} className="CommentContent">
          {comment.content}
        </pre>
      </div>
    );
  }
  return view;

  function fetchOwner(userId) {
    setLoading(true);
    getUserApi(userId)
      .then((result) => {
        cacheUser(result);
        setOwner(result);
        setLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        setLoading(false);
        setFailed(true);
      });
  }
}
export default connect(
  (state) => ({
    userCache: state.userCache,
  }),
  (dispatch) => ({
    cacheUser: (user) => {
      dispatch({ type: UserCacheAction.AddItems, items: [user] });
    },
  })
)(withUserAgent(CommentItemView));
