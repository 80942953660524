import React from "react";
import TextareaAutosize from "react-autosize-textarea";

import "./InputWithChecker.scss";

function TextAreaWithChecker({
  style,
  checker,
  inputType,
  onInputChange,
  placeholder,
  subtitle,
  defaultValue,
  className,
  inputClassName
}) {
  const [checked, setChecked] = React.useState(null);
  function onChange(event) {
    const newText = event.target.value;
    const check = checker(newText);
    onInputChange(check);
    setChecked(check);
  }
  return (
    <div className={className ? className : "InputWithCheckerContainer"}>
      {subtitle ? (
        <div className="InputWithCheckerSubtitle">{subtitle}</div>
      ) : null}
      <TextareaAutosize
        type={inputType ? inputType : "text"}
        className={inputClassName}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
        defaultValue={defaultValue}
      />
      {checked && !checked.correct && checked.error ? (
        <div className="InputWithCheckerError">{checked.error}</div>
      ) : null}
    </div>
  );
}

export default TextAreaWithChecker;
