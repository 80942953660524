import React from "react";
import { connect } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import grey from "@material-ui/core/colors/grey";

//comps

const darkTheme = createMuiTheme({
  overrides: {
    divider: grey[800],
    border: "1px solid " + grey[800],
    selectedTextColor: "black",
    selectedBackgroundColor: grey[400],
  },
  palette: {
    type: "dark",
    primary: { main: grey[300], dark: grey[800], light: grey[800] },
  },
});

function ThemeComponent({ children }) {
  const theme = darkTheme;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default connect((state) => ({
  settings: state.settings,
}))(ThemeComponent);
