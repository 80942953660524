//libs
import React from "react";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

//comps
import "./CommentComponent.scss";
import CommentCreate from "./CommentCreate";
import CommentItemView from "./CommentItemView";
import { getCommentsApi } from "../../../api_actions/CommentsApiActions";
import ReloadComponent from "../../common_components/ReloadComponent";
import { withUserAgent } from "react-useragent";

function CommentComponent({ story, ua }) {
  const [failed, setFailed] = React.useState(false);
  const [comments, setComments] = React.useState(null);
  const [nextToken, setNextToken] = React.useState(null);
  const canShowComments = story.published && comments;
  const isMobile = ua.mobile;
  React.useEffect(() => {
    if (!comments && !failed) loadComments(story.id);
  });

  function loadComments(storyId) {
    getCommentsApi(storyId, nextToken)
      .then(data => {
        let newComments = data.items;
        if (comments) {
          newComments = [...comments, ...data.items];
        }
        setComments(newComments);
        setNextToken(data.nextToken);
      })
      .catch(error => {
        setFailed(true);
        console.warn(error);
      });
  }

  function commentAdded(comment) {
    let newComments = [comment, ...comments];
    setComments(newComments);
  }

  let view = <div />;
  if (failed) {
    view = (
      <ReloadComponent
        onClick={() => {
          loadComments(story.id);
        }}
      />
    );
  }
  if (canShowComments) {
    view = (
      <div className="CommentsComponent">
        <Divider />
        <CommentCreate storyId={story.id} onCommentAdded={commentAdded} />
        {comments.length > 0 ? (
          <div className={isMobile ? "MobileCommentsTitle" : "CommentsTitle"}>
            Other comments:
          </div>
        ) : null}
        {comments.map(item => {
          return <CommentItemView key={item.id} comment={item} />;
        })}
        {nextToken ? (
          <div className="CommentLoadMoreButtonContainer">
            <Button
              onClick={() => {
                loadComments(story.id);
              }}
            >
              Load More
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
  return view;
}

export default withUserAgent(CommentComponent);
