//comps
import ErrorSnackbar from "./components/common_components/ErrorSnackbar";
import IndexPage from "./pages/indexPage";
import PageLoader from "./pages/PageLoader";
import Privacy from "./pages/privacy";
import LoginRedirect from "./pages/loginRedirect";
import LogoutRedirect from "./pages/logoutRedirect";
import MobileUsersPage from "./pages/MobileUsersPage";
import MyProfile from "./pages/MyProfile";
import DevPage from "./pages/DevPage";
import ThemeComponent from "./components/common_components/ThemeComponent";
//libs
import React from "react";
import { withUserAgent } from "react-useragent";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Amplify from "aws-amplify";
import getAmplifyConfig from "./getAmplifyConfig";
Amplify.configure(getAmplifyConfig());

function NoMatch() {
  return (
    <div>
      <h3>404 Page not Found</h3>
    </div>
  );
}

function App() {
  return (
    <ThemeComponent>
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/index.html" component={IndexPage} />
          <Route exact path="/dev" component={DevPage} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/login-redirect" component={LoginRedirect} />
          <Route exact path="/logout-redirect" component={LogoutRedirect} />
          <Route exact path="/mobileUsers" component={MobileUsersPage} />
          <Route exact path="/myprofile" component={MyProfile} />
          <Route path="/p/:pageId/:storyId" children={<PageLoader />} />
          <Route path="/p/:pageId" children={<PageLoader />} />
          <Route path="*" component={NoMatch} />
        </Switch>
        <ErrorSnackbar />
      </Router>
    </ThemeComponent>
  );
}

export default withUserAgent(App);
