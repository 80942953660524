//libs
import React from "react";
import { connect } from "react-redux";

//comps
import { createStory } from "../../api_actions/storyApiActions";
import LoadingItem from "./LoadingItem";
import { PageAction } from "../../reducers/pageReducer";
import { ErrorAction } from "../../reducers/errorReducer";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";

function AddItem({
  user,
  onAddStorySuccess,
  history,
  onAddStoryFailed,
  className,
  loadingClassName,
}) {
  const [isLoading, setLoading] = React.useState(false);

  function handleClick() {
    setLoading(true);
    createStory(user.user.id)
      .then((result) => {
        onAddStorySuccess(result);
        setLoading(false);
        history.push(`/p/${user.user.id}/${result.id}`);
      })
      .catch((err) => {
        setLoading(false);
        onAddStoryFailed(err);
      });
  }

  const theme = useTheme();
  const textColor = theme.palette.text.primary;
  const backgroundColor = theme.palette.background.default;

  const border = theme.overrides.border;
  const regularStyle = {
    color: textColor,
    borderBottom: border,
    background: backgroundColor
  };

  return isLoading ? (
    <LoadingItem className={loadingClassName} />
  ) : (
    <div
      style={regularStyle}
      className={className ? className : "AddItem"}
      onClick={handleClick}
    >
      <div className="PageSideFragmentItemText">Create story</div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    onAddStorySuccess: (result) => {
      dispatch({ type: PageAction.AddStory, payload: { story: result } });
    },
    onAddStoryFailed: (err) => {
      dispatch({
        type: ErrorAction.OnError,
        payload: { err: err, message: "Add item failed" },
      });
    },
  })
)(withRouter(AddItem));
