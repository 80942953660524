//libs
import React from "react";
import Auth from "@aws-amplify/auth";

const LogoutButton = ({ beforeLogout }) => {
  const onClick = () => {
    if (beforeLogout) beforeLogout();
    Auth.signOut()
      .then(() => {})
      .catch(e => {
        console.warn(e);
      });
  };
  return (
    <div onClick={onClick} className="LogoutButton">
      Log out
    </div>
  );
};

export default LogoutButton;
